
import { BaseService } from "./Common/BaseService";
 
export class ConversationService extends BaseService {
    constructor() {
        super("Conversation");
    }
    GetConversation() {
        return this.client.Get(`${this.baseUrl}/GetConversations`);
    }
    GetConversationById(ConversationId) {
        return this.client.Get(`${this.baseUrl}/GetConversationById?ConversationId=`+ConversationId);
    }
    AddConversation(toUserId) {
        return this.client.Post(`${this.baseUrl}/AddConversation?toUserId=${toUserId}`);
    }
}

