
import axios from "./axios-service";

export class HttpClient {
  Get(url) {    
    return axios.get(url);
  }
  Post(url, item) {
    return axios.post(url, item);
  }
  Put(url, item) {
    return axios.put(url, item);
  }
  Delete(url, item) {
    return axios.delete(url, item);
  }
}
