import { defineStore } from "pinia";


export const useLoadingStore = defineStore("loadingStore",{
    state: () => ({
        status:false
      }),

    actions:{
        setLoadingStatus(status){
            this.status = status;
        }
    }  
})

