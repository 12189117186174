
import { BaseService } from "./Common/BaseService";

export class ProfileService extends BaseService {
    constructor() {
        super("User");
    }
    ProfileService() {
        return this.client.Get(`${this.baseUrl}/Profile`);
    }
    UpdateProfileService(payload) {
        return this.client.Post(`${this.baseUrl}/UpdateProfile`, payload);
    }
    AddUser(command) {
        return this.client.Post(`${this.baseUrl}/AddUser`, command);
    }
    UpdateProfileByUserId(payload) {
        return this.client.Post(`${this.baseUrl}/UpdateProfileByUserId`, payload);
    }
    GetMySignalRConnectionId() {
        return this.client.Get(`${this.baseUrl}/GetMySignalRConnectionId`);
    }
    ProfileByUserId(UserId) {
        return this.client.Get(`${this.baseUrl}/ProfileByUserId?UserId=${UserId}`);
    }
    DeleteProfilAvatarByUserId(UserId) {
        return this.client.Delete(`${this.baseUrl}/DeleteProfilAvatarByUserId?UserId=${UserId}`);
    }
}