import { defineStore } from "pinia";


export const useTheme = defineStore("themeStore",{
    state: () => ({
        status:true
      }),

    actions:{
        setThemeStatus(){
            this.status = !this.status;
            localStorage.setItem("themeStatus",this.status)
        },
        getThemeStatus(){
            this.status = JSON.parse(localStorage.getItem("themeStatus"))
            if (this.status) {
                document.documentElement.setAttribute("data-bs-theme", "light");
              } else {
                document.documentElement.setAttribute("data-bs-theme", "dark");
              }
        }
    }  
})
