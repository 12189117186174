
import { BaseService } from "./Common/BaseService";

export class MessageService extends BaseService {
    constructor() {
        super("Message");
    }
    GetMessage(conversationId, LastMessageId = 0) {
        let fetchUrl = `${this.baseUrl}/GetMessage?conversationId=${conversationId}`;
        if(LastMessageId != 0){
            fetchUrl += `&LastMessageId=${LastMessageId}`;
        }
        return this.client.Get(fetchUrl);
    }
    SendMessage(payload) {
        return this.client.Post(`${this.baseUrl}/SendMessage`, payload);
    }
    DeleteMessage(MessageId){
        return this.client.Get(`${this.baseUrl}/DeleteMessage?MessageId=${MessageId}`);
    }
    GetBlob(resimAdi){
        return this.client.Get(`${this.baseUrl}/GetBlob?resimAdi=${resimAdi}`);
    }
}