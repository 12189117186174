import { defineStore } from "pinia";
import { AuthService } from "@/services/AuthService";
import { ProfileService } from "@/services/ProfileService";
import router from "@/router";
import { useToast } from "vue-toast-notification";
import { MAIN_BASE } from "@/config/settings";
import { useLoadingStore } from "./loading";

const toast = useToast()
const user = JSON.parse(localStorage.getItem("user"));
const authService = new AuthService();
const profileService = new ProfileService();

export const useAuthStore = defineStore("auth", {
  state: () => ({
    userStatus: {},
    user: {},
    token: "",
  }),
  getters: {
    loggedIn() {
      return !!user;
    },
    getUserAvatar() {
      return this.user.avatar ? MAIN_BASE + this.user.avatar : require('@/assets/images/defaultProfile.jpg')
    }
  },
  actions: {
    login(body) {
      const loadingStore = useLoadingStore();
      loadingStore.setLoadingStatus(true);
      authService
        .Login(body)
        .then((res) => {
          this.token = res.data.data.jwtToken;
          localStorage.setItem("token", res.data.data.jwtToken);
          this.userStatus = { loggeduser: true };
          this.getUser();
          router.push("/");
        })
        .catch((error) => {
          toast.error("Telefon Numarası veya Şifre Hatalı")
          console.log(error);
          this.userStatus = {};
          this.user = {};
        }).finally(() => loadingStore.setLoadingStatus(false));
    },
    async getUser() {
      const loadingStore = useLoadingStore();
      loadingStore.setLoadingStatus(true);
      try {
        const res = await profileService.ProfileService();
        if (res.status === 200) {
          this.user = res.data.data;
        }
      } catch (err) {
        console.error(err);
        this.userStatus = {};
        this.user = {};
      } finally {
        loadingStore.setLoadingStatus(false);
      }
    },
    logout() {
      this.userStatus = {};
      this.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      router.push("/login");
    },
    resetAuthStore() {
      this.userStatus = {};
      this.user = {};
      this.token = "";
    }
  },
});
