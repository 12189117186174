import { defineStore } from "pinia";
import { MessageService } from '@/services/MessageService.js'
import { ConversationService } from "@/services/ConversationService";
import { useToast } from "vue-toast-notification";
import { useLoadingStore } from "./loading";
import { useConversationStore } from "./conversation";

const toast = useToast()
const messageService = new MessageService();
const conversationService = new ConversationService();

export const useChatStore = defineStore("chatStore", {
    state: () => ({
        messageList: [],
        chatInfo: {},
        currentLastMessageId: 0,
        activeChat: false
    }),
    getters: {
        getActiveChat: state => state.activeChat,
        getSortedMessageList: state => state.messageList.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate))
    },
    actions: {
        async fetchMessageList(id, isIncreaseList = false) {
            let currentLastMessageId = 0;
            if (isIncreaseList) {
                currentLastMessageId = this.getSortedMessageList[this.getSortedMessageList.length - 1].messageId || 0;
            }
            try {
                const res = await messageService.GetMessage(id, currentLastMessageId)
                if (res.status === 200) {
                    this.messageList = currentLastMessageId ? [...this.messageList, ...res.data] : res.data;
                }
            } catch (error) {
                console.error(error)
                toast.error('Bir hata oluştu')
            }
        },
        async fetchChatInfo(userId) {
            const loadingStore = useLoadingStore()
            loadingStore.setLoadingStatus(true)
            try {
                const res = await conversationService.GetConversationById(userId)

                if (res.status === 200) {
                    this.chatInfo = res.data.data[0]
                    this.activeChat = true
                }
            } catch (error) {
                console.error(error)
                toast.error('Bir hata oluştu')
            } finally {
                loadingStore.setLoadingStatus(false)
            }
        },
        async sendMessage(payload) {
            const conversationStore = useConversationStore();
            const res = await messageService.SendMessage(payload);
            if (res.status === 200) {
                await this.fetchMessageList(this.chatInfo.id, this.messageList.length > 0 ? true : false)
                conversationStore.updateConversationObject(this.chatInfo.id, payload.get("Content"), true)
            } else {
                toast.error('Bir hata oluştu')
            }
        },
        async fetchDeleteMessage(payload) {
            try {
                const res = await messageService.DeleteMessage(payload);
                if (res.status === 200) return res.data
            } catch (error) {
                console.error(error)
            }
        },
        changeMessageItemToDeleted(messageId) {
            const deletedMessageIndex = this.messageList.findIndex(x => x.messageId === messageId);
            if (deletedMessageIndex !== -1) {
                this.messageList[deletedMessageIndex].isActive = false;
            }
        },
        async downloadFile(filePath) {
            try {
                const res = await messageService.GetBlob(filePath);
                if (res.status === 200) return res.data.data
            } catch (error) {
                console.error(error)
            }
        },
        resetChatStore() {
            this.messageList = []
            this.chatInfo = {}
            this.currentLastMessageId = 0;
            this.activeChat = false
        }
    },
});
