import { BaseService } from "./Common/BaseService";

export class AuthService extends BaseService {
  constructor() {
    super("Auth");
  }
  Login(command) {
    return this.client.Post(`${this.baseUrl}/login`, command);
  }
}
