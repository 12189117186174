import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
});

router.beforeEach((to,from,next) => {
  if(to.path === '/' && !localStorage.getItem('token')){
    return next({ path: "/login" });
  }else if(to.path === '/login'  && localStorage.getItem('token')){
    return next({ path: '/' })
  }
  next()
});

export default router;
