import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia'
import BootstrapVueNext from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import 'emoji-picker-element';
import 'vue-toast-notification/dist/theme-sugar.css';


const pinia = createPinia()

createApp(App)
    .use(pinia)
    .use(router)
    .use(BootstrapVueNext)
    .mount('#app');