export default [
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("@/pages/chat/index"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/account/login.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("@/pages/account/forgot-password.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/pages/account/login.vue"),
  },
  {
    path: "/lock-screen",
    name: "lock-screen",
    component: () => import("@/pages/account/lock-screen"),
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import("@/pages/chat/index"),
  },

  {
    path: "/userlist",
    name: "userlist",
    component: () => import("@/pages/chat/index"),
  },
  
  {
    path: "/groups",
    name: "groups",
    component: () => import("@/pages/chat/index"),
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("@/pages/chat/index"),
  },

  {
    path: "/settings",
    name: "settings",
    component: () => import("@/pages/chat/index"),
  },
];
