import { API_BASE } from "@/config/settings";
import router from "@/router";

import axios from "axios";

const axiosConf = axios.create({ baseURL: API_BASE });
axiosConf.interceptors.request.use(
  (config) => {
    const userToken = localStorage.getItem("token") || "";
    if (userToken !== "") {
      if (config.headers) {
        config.headers["Authorization"] = userToken;
      }
    }
    return config;
  },
  function (error) {
    console.log("REQ ERROR ", error);
  }
);

axiosConf.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.name === "AxiosError" &&
      error.message !== "Request failed with status code 400"
    ) {
      localStorage.removeItem("token");
      router.push({ path: "/login" });
      return Promise.reject(error);
    }
  }
);

export default axiosConf;
