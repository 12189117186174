import { defineStore } from "pinia";
import { ConversationService } from "@/services/ConversationService";
import { useToast } from "vue-toast-notification";
import { useLoadingStore } from "./loading";

const toast = useToast()
const conversationService = new ConversationService();

export const useConversationStore = defineStore("conversationStore", {
    state: () => ({
        conversationList: [],
        filteredConversationList: [],
        conversationSearchText: ""
    }),
    getters: {
        getSortedFilteredConversationList() {
            return this.filteredConversationList.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate))
        }
    },
    actions: {
        setConversationSearchText(text) {
            this.conversationSearchText = text
            console.log(this.conversationSearchText);
        },
        fetchConversationList() {
            const loadingStore = useLoadingStore()
            loadingStore.setLoadingStatus(true)

            conversationService.GetConversation().then((res) => {
                if (res.status == 200) {
                    this.conversationList = res.data.data;
                    this.filteredConversationList = res.data.data
                }
            }).catch(error => {
                console.error(error);
                toast.error('Bir hata oluştu !');
            }).finally(() => {
                loadingStore.setLoadingStatus(false)
            });
        },
        setFilteredConversationList() {
            this.filteredConversationList = this.conversationList.filter(item => {
                const lowerCaseToUserName = item.toUserName.toLowerCase();
                const lowerCaseSearchInput = this.conversationSearchText.toLowerCase();
                return lowerCaseToUserName.includes(lowerCaseSearchInput);
            });
        },
        resetFilteredConversationList() {
            this.filteredConversationList = this.conversationList
        },
        updateConversationObject(conversationId, content, isAlreadyHere) {
            this.conversationSearchText = ""
            this.conversationList.map(x => {
                if (x.id === conversationId) {
                    x.modifiedDate = new Date().toString()
                    if (x.lastMessage) {
                        x.lastMessage.content = content
                        x.lastMessage.messageId = x.lastMessage.messageId + 1
                    }

                    if (!isAlreadyHere) {
                        x.unreadedMessageCount = 1;
                    }
                }
            })
            this.filteredConversationList = this.conversationList;
        },
        resetUnreadedCount(conversationId) {
            this.conversationList.map(x => {
                if (x.id === conversationId) {
                    x.unreadedMessageCount = 0;
                }
            })
        }
    }
});